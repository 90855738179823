/* eslint-disable no-empty */
import {
  defineNuxtPlugin,
  useNuxtApp,
  useCookie,
  useRuntimeConfig
} from '#imports'
import { COOKIE_CONSENT, UTM_COOKIES } from '~/utils/constants'

export const getUtmCookies = () => {
  return UTM_COOKIES.reduce((allParams, param) => {
    const cookieName = 'bc_ft_' + param
    const cookie = useCookie(cookieName, { maxAge: 60 * 60 * 24 * 120 })

    if (cookie.value) {
      allParams[cookieName] = cookie.value
    }

    return allParams
  }, {})
}

export const sendTrackingEvent = async (trackingEvent) => {
  try {
    const { $localStorage } = useNuxtApp()
    if (!$localStorage.getItem(COOKIE_CONSENT).marketingCookies) return

    const config = useRuntimeConfig()
    const data = {
      ...trackingEvent.data,
      ...getUtmCookies()
    }

    await fetch('https://trackcmp.net/event', {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body:
        'actid=' +
        config.public.ACTIVE_CAMPAIGN_ACTID +
        '&key=' +
        config.public.ACTIVE_CAMPAIGN_KEY +
        '&event=' +
        trackingEvent.eventName +
        '&visit=' +
        encodeURIComponent(JSON.stringify({ email: trackingEvent.email })) +
        '&eventdata=' +
        JSON.stringify(data)
    })
  } catch {}
}

export default defineNuxtPlugin(() => {
  return {
    parallel: true,
    provide: {
      activeCampaign: {
        sendTrackingEvent: (trackingEvent) => {
          sendTrackingEvent(trackingEvent)
        }
      }
    }
  }
})
