/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGtm, useGtm } from '@gtm-support/vue-gtm'
import { COOKIE_CONSENT } from '~/utils/constants'

interface Consent {
  marketingCookies: boolean
  functionalCookies: boolean
  analyticalCookies: boolean
}

export const currency = 'EUR'

export const getGA4Items = (items: any) => {
  return items.map((item: any, index: number) => ({
    item_id: item.id,
    item_name: item.name,
    price: item.price,
    discount: item.discount || 0,
    coupon: item.coupon || null,
    item_list_id: item.itemListId,
    item_list_name: item.itemListName,
    index: item.visibilityOrderPosition || index,
    currency,
    quantity: 1
  }))
}

function gtag(...args: any) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(args)
}

export const setConsent = (
  { marketingCookies, functionalCookies, analyticalCookies }: Consent,
  consentType: string
) => {
  if (!analyticalCookies) return

  gtag('consent', consentType, {
    functionality_storage: functionalCookies ? 'granted' : 'denied',
    personalization_storage: functionalCookies ? 'granted' : 'denied',
    security_storage: 'granted',
    analytics_storage: analyticalCookies ? 'granted' : 'denied',
    ad_storage: marketingCookies ? 'granted' : 'denied'
  })

  gtag('set', 'url_passthrough', analyticalCookies)
  gtag('set', 'ads_data_redaction', !marketingCookies)
  gtag('set', 'restricted_data_processing', !marketingCookies)
  gtag('set', 'allow_google_signals', marketingCookies)
  gtag('set', 'allow_ad_personalization_signals', marketingCookies)
}

export const pushNuxtRouteEvent = (gtm: any) => {
  setTimeout(
    () =>
      gtm.trackEvent({
        event: 'nuxt_route'
      }),
    250
  )
}

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig()

  const options = {
    id: config.public.GTM as string,
    queryParams: {
      gtm_auth: config.public.GTM_AUTH as string,
      gtm_preview: config.public.GTM_PREVIEW as string,
      gtm_cookies_win: 'x'
    },
    defer: true,
    enabled: false,
    debug: false,
    loadScript: true,
    vueRouter: useRouter()
  }

  nuxtApp.vueApp.use(createGtm(options))

  const gtm = useGtm()

  return {
    provide: {
      gtmTracking: {
        setConsent,
        pushNuxtRouteEvent: () => pushNuxtRouteEvent(gtm),
        push(event: any) {
          if (
            nuxtApp.$localStorage.getItem(COOKIE_CONSENT)?.analyticalCookies
          ) {
            gtm?.trackEvent(event)
          }
        },
        viewItemList({ products, itemListId, itemListName }: any) {
          this.push({ ecommerce: null })
          this.push({
            event: 'view_item_list',
            ecommerce: {
              item_list_id: itemListId,
              item_list_name: itemListName,
              items: getGA4Items(products)
            }
          })
        },
        selectItem({ item, itemListId, itemListName }: any) {
          this.push({ ecommerce: null })
          this.push({
            event: 'select_item',
            ecommerce: {
              item_list_id: itemListId,
              item_list_name: itemListName,
              items: getGA4Items([item])
            }
          })
        },
        viewItem({ item, value, itemListId, itemListName }: any) {
          this.push({ ecommerce: null })
          this.push({
            event: 'view_item',
            ecommerce: {
              currency,
              value,
              item_list_id: itemListId,
              item_list_name: itemListName,
              items: getGA4Items([
                {
                  ...item,
                  itemListId,
                  itemListName
                }
              ])
            }
          })
        },
        removeFromCart(item: object, value: number) {
          this.push({ ecommerce: null })
          this.push({
            event: 'remove_from_cart',
            ecommerce: {
              currency,
              value,
              items: getGA4Items([item])
            }
          })
        },
        addToCart(item: object, value: number) {
          this.push({ ecommerce: null })
          this.push({
            event: 'add_to_cart',
            ecommerce: {
              currency,
              value,
              items: getGA4Items([item])
            }
          })
        },
        viewCart(value: number, items: []) {
          this.push({ ecommerce: null })
          this.push({
            event: 'view_cart',
            ecommerce: {
              currency,
              value,
              items: getGA4Items(items)
            }
          })
        },
        checkout({ products, totalPrice, coupon = null }: any) {
          this.push({ ecommerce: null })
          this.push({
            event: 'begin_checkout',
            ecommerce: {
              currency,
              value: totalPrice,
              coupon,
              items: getGA4Items(products)
            }
          })
        },
        purchase({ id, revenue, coupon = null, products }: any) {
          this.push({ ecommerce: null })
          this.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: id,
              value: revenue,
              currency,
              coupon,
              items: getGA4Items(products)
            }
          })
        },
        becomeMemberClicked(place: string) {
          this.push({
            event: 'interact_event',
            eventAction: 'click',
            eventCategory: `BecomeMember || ${place}`,
            eventLabel: 'to:aanmelden'
          })
        }
      }
    }
  }
})
