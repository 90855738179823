<script setup>
import { useStore } from 'vuex'
import { useGtm } from '@gtm-support/vue-gtm'

const store = useStore()
const { $sentryCaptureException, $gtmTracking } = useNuxtApp()
const { sentrySetUserId } = useSentry()
const router = useRouter()
const gtm = useGtm()
const { data } = useAuth()

const { error } = await useAsyncData(() => {
  store.commit('setUser', data.value)
  return store.dispatch('getLabels')
})

if (error.value) {
  $sentryCaptureException(error.value)
}

// update user data if token expires
watch(data, (newData) => {
  if (!newData && store.getters.loggedInUser) {
    store.commit('setUser', false)
  }
})

const updateTabs = (event) => {
  if (event.key === 'sessionStatus' || event.key === 'chosenBook') {
    window.location.reload()
  }
}

onMounted(() => {
  window.addEventListener('storage', updateTabs)
})

onBeforeUnmount(() => {
  window.removeEventListener('storage', updateTabs)
})

router.afterEach((_, from) => {
  store.commit('route/setFrom', from)

  // remove ocp scroll lock class on routing
  document.body.classList.remove('scroll-lock')
  document.body.removeAttribute('style')

  store.dispatch('dismissToastMessage')
  store.dispatch('updateDrawerState', { isDrawerShown: false })

  if (gtm.enabled) {
    $gtmTracking.pushNuxtRouteEvent()
  }
})

if (import.meta.client) {
  sentrySetUserId()
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage :page-key="(route) => route.path" />
  </NuxtLayout>
</template>

<style>
.page--start {
  transition: all 0.2s ease-out;
  opacity: 0.2;
}
.page--finish {
  opacity: 1 !important;
}
</style>
