import { $fetchApi } from '~/composables/useFetchApi'

export const books = {
  namespaced: true,
  state: () => ({
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monthlyBooks: [],
    bookDetails: {},
    extraCategories: null,
    chosenBooksData: {}
  }),
  getters: {
    categories(_, getters) {
      return (variant = [0]) => {
        const now = new Date()
        const categories = variant.map((monthModifier) => {
          const newDate = new Date(now.getTime())
          newDate.setDate(1)
          newDate.setMonth(now.getMonth() + monthModifier)

          return {
            date: newDate,
            name: getters.month(newDate.getMonth()),
            value: `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
              .toString()
              .padStart(2, '0')}`
          }
        })

        return categories
      }
    },
    monthlyBooks(state) {
      return (month) => state.monthlyBooks[month] || []
    },
    bookDetails(state) {
      return (bookSlug = '') => {
        const slug = bookSlug.toLowerCase()

        return state.bookDetails[slug] || {}
      }
    },
    month(state) {
      return (month) => {
        return `common.${state.months[month]}`
      }
    },
    getBooksForMonth(_, getters) {
      return (month) => {
        return getters
          .monthlyBooks(month)
          .map((book) => Object.assign({}, book))
      }
    },
    extraCategories(state) {
      return state.extraCategories || []
    },
    chosenBooks(state) {
      return (month) => state.chosenBooksData[month]?.chosenBooks
    },
    allChosenBooks(state) {
      return state.chosenBooksData
    },
    choicesLeft(state) {
      return (month) => state.chosenBooksData[month]?.choicesLeft
    }
  },
  mutations: {
    monthlyBooks(state, { category, books }) {
      if (category && books) {
        state.monthlyBooks = {
          ...state.monthlyBooks,
          [category]: books.map((book) => ({
            ...book,
            categoryCode: category
          }))
        }
      }
    },
    bookDetails(state, { slug, ...restBook }) {
      state.bookDetails = {
        ...state.bookDetails,
        [slug]: {
          ...restBook,
          slug
        }
      }
    },
    setExtraCategories(state, extraCategories) {
      state.extraCategories = extraCategories
    },
    setChosenBooksData(state, data) {
      state.chosenBooksData = {
        ...state.chosenBooksData,
        ...data
      }
    },
    setEmptyChosenBooksData(state) {
      state.chosenBooksData = {}
    }
  },
  actions: {
    async getMonthlyBooks({ commit, state, getters }, category) {
      if (!category) {
        const categories = getters.categories()
        const { value } = categories.pop()
        category = value
      }

      const { monthlyBooks } = state

      if (monthlyBooks && monthlyBooks[category]) {
        return monthlyBooks[category]
      }

      let params = {}

      if (category) {
        params = {
          category
        }
      }

      const data = await $fetchApi('v2/proxy/books/monthly-books', { params })

      commit('monthlyBooks', { category, books: data })

      return data
    },
    async getExtraCategories({ commit, state, rootState }) {
      if (!rootState.user.data) {
        return []
      }

      if (state.extraCategories) {
        return state.extraCategories
      }

      const data = await $fetchApi('v3/books/extraCategory/subscription')

      commit('setExtraCategories', data)

      return data
    },
    clearBooksData({ commit }) {
      commit('setExtraCategories', null)
      commit('setEmptyChosenBooksData')
    },
    async getBookDetails({ commit }, { slug }) {
      const result = await $fetchApi('proxy/books/monthly-books/details', {
        params: { slug }
      })

      commit('bookDetails', result)

      return result
    },
    async getEbookDownloadUrl(
      _,
      { id, format, language, categoryCode, isReader }
    ) {
      const deviceType = isReader ? 'Reader' : 'Website'
      const response = await $fetchApi(`/v3/books/my-ebooks/${id}/download`, {
        method: 'POST',
        body: {
          format,
          language,
          categoryCode
        },
        headers: { 'X-Device-Type': deviceType }
      })

      return response
    },
    async getAudioBookDownloadUrl(
      _,
      { id, format, language, categoryCode, isPlayer }
    ) {
      const deviceType = isPlayer ? 'Reader' : 'Website'

      const response = await $fetchApi(
        `/v3/books/my-audiobooks/${id}/download`,
        {
          method: 'POST',
          body: {
            format,
            language,
            categoryCode
          },
          headers: { 'X-Device-Type': deviceType }
        }
      )

      return response
    },
    async getChosenBooks({ commit }, category) {
      const response = await $fetchApi(
        `books/v1/userBookChosen/basic?category=${category}`
      )

      const { choicesLeft, chosenBooks } = response

      commit('setChosenBooksData', {
        [category]: {
          choicesLeft,
          chosenBooks
        }
      })

      return chosenBooks
    },
    async confirmChosenBook(_, bookSlug) {
      await $fetchApi('/books/v1/userBookChosen', {
        method: 'POST',
        body: JSON.stringify(bookSlug)
      })
    }
  }
}
