/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash.get'
import { useStore } from 'vuex'
import type { Store } from 'vuex'

interface VuexStore {
  label: object
}

function replaceAll(string: string, replaceValues: any) {
  if (replaceValues) {
    const reg = new RegExp(
      Object.keys(replaceValues)
        .map((key) => `{{\\s?${key}\\s?}}`)
        .join('|'),
      'gmi'
    )
    return string.replace(reg, (matched) => {
      const key = matched.replace(/{|}/g, '').trim()
      return `${replaceValues[key]}`
    })
  }

  return string
}

function getLabel(
  labelString: string,
  replaceValues: object | undefined,
  store: Store<VuexStore>
) {
  const { query } = useRoute()
  const { showlabels } = query

  if (showlabels) {
    return labelString
  } else {
    return replaceAll(
      get(store.getters.labels, labelString, labelString),
      replaceValues
    )
  }
}

export default defineNuxtPlugin(() => {
  const store = useStore()

  return {
    provide: {
      label: (string: string, replaceValues: object | undefined) =>
        getLabel(string, replaceValues, store)
    }
  }
})
