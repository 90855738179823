import JSEncrypt from 'jsencrypt'
import { useRuntimeConfig } from '#imports'

const encrypt = (data) => {
  const config = useRuntimeConfig()
  const encryter = new JSEncrypt()
  encryter.setPublicKey(config.public.CRYPTO_PUBL)

  return encryter.encrypt(JSON.stringify(data))
}

const decrypt = (dataString) => {
  const config = useRuntimeConfig()
  const decryter = new JSEncrypt()
  decryter.setPrivateKey(config.public.CRYPTO_PRIV)

  try {
    return JSON.parse(decryter.decrypt(dataString))
  } catch {
    return null
  }
}

export { encrypt, decrypt }
