import { UTM_COOKIES } from '~/utils/constants'
import type { EventHandlerResponse } from 'h3'

export const getUtmCookies = () => {
  try {
    let cookies = ''

    UTM_COOKIES.map((name) => {
      const fullName = 'bc_ft_' + name
      const cookie = useCookie(fullName, { maxAge: 60 * 60 * 24 * 120 })
      if (cookie.value) {
        cookies = cookies + `${fullName}=${cookie.value}; `
      }
    })

    return cookies
  } catch {
    return ''
  }
}

export const getHeaders = () => {
  const { token } = useAuth()
  const headers: Record<string, string> = {
    'X-Device-Type': 'Website'
  }
  const utmCookies = getUtmCookies()

  if (token.value) {
    headers['X-Authorization'] = token.value
  }

  if (utmCookies) {
    headers['X-Utm-Cookie'] = utmCookies
  }

  return headers
}

interface onResponseErrorParams {
  response: EventHandlerResponse
}

export const onResponseError = async ({ response }: onResponseErrorParams) => {
  if (response?.status === 401) {
    const { signOut } = useAuth()
    await signOut({ redirect: false })

    const route = useRoute()
    if (route.meta?.middleware === 'authentication') {
      await navigateTo('/')
    }
  }
}

export async function useFetchApi(url: string, options = { headers: {} }) {
  const config = useRuntimeConfig()

  return useFetch(url, {
    ...options,
    baseURL: config.public.API_URL,
    headers: { ...getHeaders(), ...options.headers },
    onResponseError
  })
}

export async function $fetchApi(url: string, options = { headers: {} }) {
  const config = useRuntimeConfig()

  return $fetch(url, {
    ...options,
    baseURL: config.public.API_URL,
    headers: { ...getHeaders(), ...options.headers },
    onResponseError
  })
}
