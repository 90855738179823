/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLabel = (error: any) => {
  const responseData = error.response?._data || error.data

  if (Array.isArray(responseData)) {
    const [errorInfo] = responseData
    return errorInfo.code
      .split('.')
      .map((string: string) => string.charAt(0).toLowerCase() + string.slice(1))
      .join('.')
  }

  return 'messages.errorOccuredMessage'
}

export const getErrorCode = (error: any) => {
  if (error.response && Array.isArray(error.response._data)) {
    const [errorInfo] = error.response._data
    return errorInfo.code
  }

  return ''
}

export default defineNuxtPlugin(() => {
  return {
    parallel: true,
    provide: {
      error: {
        getLabel,
        getErrorCode
      }
    }
  }
})
